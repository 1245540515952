import React from 'react';

import BlockContent from '../BlockContent';
import { BlockContentComponent as BlockContentComponentType } from '@AuroraTypes';

export const BlockContentComponent: React.FC<BlockContentComponentType> = ({
  blockContent,
  type,
}) => (
  <BlockContent
    {...blockContent}
    type={type}
  />
);
